<template>
    <div class="messageRow" :class="{ 'myMessage' : isMyMessage }">

        <div v-if="message.attachment" class="messageItem attachment">
            <Load v-if="message.attachment.load" :load="message.attachment.load"/>
        </div>

        <div class="messageItem" v-if="message.message" v-html="message.message"></div>
        
        <div class="messageDetails" v-if="showTime || showSeen">
            <div class="time" v-if="showTime">{{ time }}</div>
            <div class="isSeen" v-if="showSeen">
                <div class="seen blueSeen" v-if="message.seen"><i class="fas fa-check-double"></i></div>
                <div class="seen" v-else><i class="fas fa-check"></i></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Load from './attachments/Load.vue'
    export default {
        props: ['message', 'showTime', 'showSeen'],
        components: {
            Load
        },
        computed: {
            ...mapGetters(['user']),
            isMyMessage() {
                return this.message.from == this.user._id;
            },
            time() {
                let date = new Date(this.message.date);
                return ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) ;
            }
        }
    }
</script>

<style lang="scss" scoped>
.messageRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    &.myMessage {
        align-items: flex-end;
        .messageItem {
            background: $buttonColor;
            color: #fff;
            border-radius: 10px 10px 1px 10px;
        }
    }
}

.messageDetails {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .isSeen {
        margin-left: 10px;
    }
}

.messageItem {
    background: $bgSecondary;
    width: auto;
    box-shadow: $boxShadow;
    padding: 10px;
    border-radius: 10px 10px 10px 1px;
    max-width: 350px;
    position: relative;
    overflow-wrap: break-word;
    &.attachment {
        margin-bottom: 5px;
    }
}

.seen {
    font-size: 12px;
    &.blueSeen {
        color: $buttonColor;
    }
}

.time {
    font-size: 12px;
}
</style>