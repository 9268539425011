<template>
    <div class="chatGridHolder">

        <div class="title chatsTitle">
            <h2>Chats</h2>
            <button @click="switchSearch" class="default small reverse">
                <span v-if="!searchUsers"><i class="fas fa-search"></i></span>
                <span v-else><i class="fas fa-comments"></i></span>
            </button>
        </div>

        <div class="scrollableContent">
            <div class="usersList" v-if="searchUsers">
                <input type="text" placeholder="Search" v-model="search" class="searchUsersInput">

                <div class="list" v-if="filterUsers.length > 0">
                    <div class="item" :key="key" v-for="(item, key) of filterUsers" @click="selectChat(null, item)">
                        <div class="image">
                            <div class="img" :style="`background-image: url('${item.profileImage}')`">
                            </div>
                        </div>

                        <div class="_name">{{ item.name }}</div>

                    </div>
                </div>

                <div class="usersList noChatsMessage" v-else>
                    <div class="text">No users found</div>
                </div>

            </div>

            <div class="chatsList" v-else-if="chats.length > 0">
                <ChatItem :key="key" v-for="(item, key) of sortedChats" :chat="item" @selectChat="selectChat"/>
            </div>

            <div class="chatsList noChatsMessage" v-else>
                <div class="text">You don't have any chats yet</div>
                <button @click="switchSearch" class="default small round">Start chatting</button>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChatItem from './ChatItem.vue'

    export default {
        components: {
            ChatItem,
        },
        data() {
            return {
                searchUsers: false,
                chats: [],
                search: ''
            }
        },
        computed: {
            ...mapGetters(['users', 'webSocket', 'chatsUnread', 'user']),
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            filterUsers() {
                let search = this.search.toLowerCase();
                return this.users.filter(item => {
                    let name = item.name.toLowerCase();
                    if(item._id == this.user._id) return false;
                    return name.includes(search);
                });
            },
            sortedChats() {
                return this.chats.sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
            }
        },
        mounted() {
            this.getChats();
            this.addWebSocketListener();
        },
        methods: {
            switchSearch() {
                this.searchUsers = !this.searchUsers;
            },
            async updateChats(body) {
                let updated = false;
                let newChat = this.chats.map(item => {
                    if(item._id == body.chat._id) {
                        item = body.chat;
                        updated = true;
                    }
                    return item;
                })
                this.chats = newChat;
                if(!updated) { this.chats.unshift(body.chat); }

                if(body.notify) {
                    this.playNotification();
                    let permission = await Notification.requestPermission();
                    if(permission == 'granted') {
                        let title = body.chat.users[body.chat.message.from].name;
                        let message = body.chat.message.message;
                        const greeting = new Notification(title, {
                            body: message
                        });
                    }
                }

            },
            markChatAsSeen(body) {
                for(let item of this.chats) {
                    if(item._id == body.chat) item.message.seen = true;
                }
            },
            addWebSocketListener() {
                if(!this.webSocket) return;
                this.webSocket.on('newChat', this.updateChats);
                this.webSocket.on('ChatSeen', this.markChatAsSeen);
            },
            getChats() {
                this.$axios.get('/chats')
                .then(data => {
                    this.chats = data.data;
                });
            },
            getUserImage(item) {
                if(!item.image || !item.image.path) return null;
                return this.apiURL + item.image.path;
            },
            getUserIN(name) {
                return name[0] + name[1];
            },
            selectChat(chatItem, profile) {
                this.searchUsers = false;
                if(chatItem) {
                    if(chatItem.message.to == this.user._id && !chatItem.message.seen) {
                        chatItem.message.seen = true;
                    }
                }
                this.$emit('selectChat', profile);
            },
            checkUnreadChats() {
                let unread = 0;
                for(const item of this.chats) {
                    if(item.message.to == this.user._id && !item.message.seen) unread++;
                }
                this.$store.commit('chatsUnread', unread);
                return unread;
            }
        },
        watch: {
            webSocket() {
                this.addWebSocketListener();
            },
            chats: {
                deep: true,
                handler() {
                    this.checkUnreadChats();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

.chatGridHolder {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    height: 100%;
}

.noChatsMessage {
    display: grid;
    row-gap: 20px;
    padding: 20px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    height: 100%;
}

.searchUsersInput {
    width: 100%;
    height: 50px;
    border: 0;
    border-bottom: 1px solid $borderColor;
    padding: 0 20px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
}

.chatsTitle {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    width: 100%;
    z-index: 2;
    background: $bg;
}


.image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    z-index: 1;
    .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $buttonColor2;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $buttonColor;
        font-weight: bold;
    }
}

.scrollableContent {
    position: relative;
    overflow-y: auto;
    height: 100%;
}

.usersList {
    .item {
        display: grid;
        grid-template-columns: 50px minmax(0, 1fr);
        align-items: center;
        align-content: center;
        column-gap: 20px;
        row-gap: 10px;
        padding: 10px;
        border-bottom: 1px solid $borderColor;
        transition: ease 0.5s;
        cursor: pointer;
        ._name {
            font-weight: bold;
        }
        &:hover {
            background: $bgSecondary;
        }
    }
}
</style>