<template>
    <div class="load">
        <div class="name">
            <div>{{ load.origin }}</div>
            <div>{{ load.destination }}</div>
        </div>
        <button class="default small round reverse" @click="viewLoad">View</button>
    </div>
</template>

<script>
    export default {
        props: ['load'],
        methods: {
            viewLoad() {
                this.$store.state.loadManager.viewLoad = this.load._id;
            }
        },
    }
</script>

<style lang="scss" scoped>
.load {
    padding: 10px;
    display: grid;
    row-gap: 20px;
}

.name {
    display: grid;
    text-align: center;
    row-gap: 10px;
    font-size: 18px;
}
</style>