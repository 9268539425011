<template>
    <div class="item" :key="chat.date" @click="$emit('selectChat', chat, profile)">

        <div class="image">
            <div class="img" :style="`background-image: url('${profile && profile.profileImage ? profile.profileImage : ''}')`"></div>
        </div>

        <div class="messageContent">
            <div class="_name">{{ profile.name }}</div>
            <div class="message textNoWrap" v-html="chat.message.short"></div>
        </div>

        <div class="time">{{ time }}</div>
        <div class="notSeen" v-if="!isSeen"></div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

    export default {
        props: ['chat'],
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters(['user']),
            profile() {
                for(const item in this.chat.users) {
                    if(item != this.user._id) return this.chat.users[item];
                }
                return null;
            },
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            profileImage() {
                if(!this.profile.image || !this.profile.image.path) return null;
                return this.apiURL + this.profile.image.path;
            },
            profileIN() {
                return this.profile.name[0] + this.profile.name[1];
            },
            isSeen() {
                if(!this.profile) return true;
                if(this.profile._id != this.chat.message.from) return true;
                return this.chat.message.seen;
            },
            time() {
                let date = new Date(this.chat.date);
                return ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2) ;
            }
        }
    }
</script>

<style lang="scss" scoped>
.notSeen {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    background: $buttonColor;
    border-radius: 50%;
}

.time {
    position: absolute;
    top: 10px;
    right: 10px;
}

.item {
    position: relative;
    display: grid;
    grid-template-columns: 50px minmax(0, 1fr);
    align-items: start;
    align-content: start;
    column-gap: 20px;
    row-gap: 10px;
    padding: 10px;
    border-bottom: 1px solid $borderColor;
    transition: ease 0.5s;
    cursor: pointer;
    ._name {
        font-weight: bold;
    }
    &:hover {
        background: $bgSecondary;
    }
}

.message {
    max-width: 80%;
}

.messageContent {
    display: grid;
    row-gap: 10px;
}

.image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    z-index: 1;
    .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $buttonColor2;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: $buttonColor;
        font-weight: bold;
    }
}

</style>